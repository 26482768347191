a {
    color: #fff;
    cursor: pointer;
    text-decoration: none !important;

}
.error-message{
    font-size: small;
    color: red;
}
.css-vg8wr6-MuiPaper-root-MuiAlert-root {
    color: #555 !important;
    top: 10px !important;
}
.add-event-btn{
    float: right;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 9px 14px !important;
}
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
    z-index: 1 !important;
}